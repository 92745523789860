<template>
  <home-tpl>
    <b-card
      title=""
      class=" mx-auto"
      
      align-v="center">    
    <b-table-simple hover small responsive class="text-left">
    <colgroup><col><col><col></colgroup>
    <colgroup><col><col></colgroup>
    <colgroup><col></colgroup>      
    <b-thead head-variant="dark">
      <b-tr>
        <b-th colspan="3">Assunto</b-th>
        <b-th colspan="2">Contatos</b-th>
        <b-th colspan="1">Enviado Em</b-th>
      </b-tr>
    </b-thead>

    <b-tbody>
      <b-tr v-for="item in news" :key="item.id">
       
        <b-td><router-link :to="'/newsletter/ver/'+item.id">{{item.assunto}}</router-link></b-td>
        <b-td></b-td>
        <b-td></b-td>
        <b-td>{{item.consultas.nome}}</b-td>
        <b-td></b-td>
        
        <b-td>
          <template v-for="item in item.envio">
            <span :key="item.data_envio" v-if="item.data_envio">{{item.data_envio}}</span>
          </template> 
            <span v-if="!item.envio.length">
              Não Enviado
            </span>
        </b-td>
        
        
      </b-tr>

    </b-tbody>

    </b-table-simple>

      <div class="card-footer">
          <pagination :data="pg" @pagination-change-page="getResults"></pagination>
      </div>

    </b-card>
  </home-tpl>
</template>

<script>
// @ is an alias to /src

import HomeTpl from '@/tpls/HomeTpl.vue'

export default {
  name: 'Listar',
  data() {
    return {
      news:{},
        
    }
  },

  mounted() {
    // Fetch initial results
    this.getResults();
  },


  components: {
    HomeTpl,
  },
/*
  created(){
    
    let usuarioAux = this.$store.getters.getUsuario;
    if(usuarioAux){
      this.usuario = this.$store.getters.getUsuario;
      this.$http.get(this.$urlApi+'newsletter/listar/',
      {"headers": {"authorization": "Bearer "+this.$store.getters.getToken}})
        .then(response => {
          //
          if(response.data.status){
            console.log(response);
            this.news = response.data.news.data;
            //this.pg = response.data.news;
          }
        })
          
          .catch(e => {
            console.log(e)
            alert("Erro! Tente novamente mais tarde!");
          })  

      }
    },
    */
  
  methods:{
    getResults(page = 1) {
    let usuarioAux = this.$store.getters.getUsuario;
    if(usuarioAux){
      this.usuario = this.$store.getters.getUsuario;
      this.$http.get(this.$urlApi+'newsletter/listar?page=' + page,
      {"headers": {"authorization": "Bearer "+this.$store.getters.getToken}})      
      
        .then(response => {
          if(response.data.status){
          console.log(response);
          this.news = response.data.news.data;
          this.pg = response.data.news;
          }
        })
          .catch(e => {
            console.log(e)
            alert("Erro! Tente novamente mais tarde!");
          })          
      }   
    }

  },
}
  </script>

